<template>
  <div class="entrustChild">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :beforeList="beforeList"
    >
      <template #headBtnSlot
        ><v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-date-picker
          label="所属批次时间"
          type="daterange"
          v-model="batchTime"
          formatValue="yyyy-MM-dd"
          startPlaceholder="所属批次开始时间"
          endPlaceholder="所属批次结束时间"
          @change="changeDate($event, 1)"
        />
        <v-date-picker
          type="datetimerange"
          label="所属报名时间"
          v-model="createTime"
          formatValue="yyyy-MM-dd,HH:mm:ss"
          startPlaceholder="所属报名开始时间"
          endPlaceholder="所属报名结束时间"
          @change="changeDate($event, 2)"
        />
        <v-select
          :clearable="isClearable"
          :options="tenantList"
          v-model="searchParam.tenantId"
          @change="$refs.list.search()"
          label="所属租户"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getBusBatchList,
  deleteShuttleBusBatch,
  getTenantList,
  getDTenantList,
} from "./api.js";

export default {
  name: "entrustChild",
  data() {
    return {
      searchParam: {
        tenantId: "", // 租户
      },
      tenantList: [], // 租户下拉数据
      tableUrl: getBusBatchList,
      batchTime: [],
      createTime: [],
      headers: [
        {
          prop: "batchId",
          label: "批次ID",
        },
        {
          prop: "startTime",
          label: "批次开始时间",
        },

        {
          prop: "endTime",
          label: "批次结束时间",
        },
        {
          prop: "applyBeginTime",
          label: "报名开始时间",
        },
        {
          prop: "applyEndTime",
          label: "报名结束时间",
        },
        {
          prop: "tenantName",
          label: "所属租户",
        },
      ],
      isClearable: false,
    };
  },
  created() {
    // this.getTenantList();
  },

  computed: {},
  methods: {
    async beforeList() {
      await this.getDTenantList();
    },
    //获取社区列表
    async getDTenantList() {
      const res = await this.$axios.get(getDTenantList);
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.tenantId;
          item.label = item.districtName;
        });

        this.tenantList = data;
        if (this.tenantList.length > 1) {
          this.searchParam.tenantId = null;
          this.isClearable = true;
        } else {
          this.searchParam.tenantId = this.tenantList[0].tenantId;
          this.isClearable = false;
        }
      }
    },
    changeDate(val, type) {
      if (type == 1) {
        if (this.batchTime && this.batchTime.length == 2) {
          this.searchParam.startDate = this.batchTime[0];
          this.searchParam.endDate = this.batchTime[1];
        } else {
          this.searchParam.startDate = null;
          this.searchParam.endDate = null;
        }
      } else {
        if (this.createTime && this.createTime.length == 2) {
          this.searchParam.applyStartDate = this.createTime[0];
          this.searchParam.applyEndDate = this.createTime[1];
        } else {
          this.searchParam.applyStartDate = null;
          this.searchParam.applyEndDate = null;
        }
      }
    },
    toAdd() {
      this.$router.push({
        name: "redScarfManagemenForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "redScarfManagemenForm",
        query: {
          id: row.batchId,
        },
      });
    },
    toDelete(row) {
      this.$confirm(`是否删除改批次`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          batchId: row.batchId,
        };
        this.$axios
          .post(deleteShuttleBusBatch, null, { params })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            }
          });
      });
    },
    // getTenantList() {
    //   let params = {
    //     tenantId: "",
    //     tenantName: "",
    //   };
    //   this.tenantList = [];
    //   this.$axios.get(`${getTenantList}`, { params }).then((res) => {
    //     if (res.code === 200) {
    //       res.data.forEach((item) => {
    //         let obj = {};
    //         obj.label = item.tenantName;
    //         obj.value = item.tenantId;
    //         this.tenantList.push(obj);
    //       });
    //     } else {
    //     }
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.entrustChild {
  box-sizing: border-box;
  height: 100%;
}
</style>
